<template>
  <div class="terms-of-service">
    <!-- terms of service section-->
    <section class="terms-of-service-section">
      <p>Terms of Service</p>
      <div>
        Last Updated: December 7, 2021<br><br>
        Please read these Terms of Service (the “Terms”) carefully because they govern your use of the website located at https://royal.market (the “Site”), the platform on which users may post or purchase nonfungible tokens (NFTs) (the “Royal Platform”) and decentralized artist support platform accessible via the Site and corresponding mobile application (“App”) offered by Royal Markets Inc., a Delaware corporation (“Royal”). To make these Terms easier to read, the Site, our services and App are collectively called the “Services.”<br><br>
        WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND ROYAL THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 19 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION 16 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 15 (GOVERNING LAW) WILL APPLY INSTEAD.<br><br>
        NFTs PURCHASED ON THE ROYAL PLATFORM ARE NON-RETURNABLE.<br><br>
        Agreement to Terms. By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services. Notwithstanding the foregoing, the laws of some jurisdictions may limit or not permit certain provisions of this agreement, such as indemnification, the exclusion of certain warranties or the limitation of liability. In such a case, such provisions will apply only to the maximum extent permitted by the laws of such jurisdictions. Also, you may have additional legal rights in your jurisdiction, and nothing in these terms will prejudice such rights that you may have as a consumer of the Services under such applicable law.<br><br>
        Additional Terms and Policies. Your use of the Services is subject to additional terms and policies as may be posted on the Services from time to time and that will include the following<br><br>
        Privacy Policy. Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information.<br><br>
        Certain Risks. Please review our Risks Associated with Limited Digital Assets Acquired on the Royal Platform for information on risks associated with transacting with limited digital assets on the Royal Platform.<br><br>
        Artist Terms and Purchaser Terms. If you are an Artist or a Purchaser (as each is defined below), you will be subject to the Artist Terms (as defined below) or Purchaser Terms, as applicable.<br><br>
        Royal Programs. Your participation in certain Royal programs (e.g., promotions, contests, etc.) will be subject to additional terms and conditions specific to such programs.<br><br>
        Changes to these Terms or the Services. We may update the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site, to the App and/or may also send other communications. It’s important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms it means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
        Who May Use the Services? The Services are only available to users in certain jurisdictions who can use the Services as permitted under applicable law. The Services are not authorized for use in certain other jurisdictions in which the United States has embargoed goods or has otherwise applied any economic sanctions. Without limitation, you must be at least 18 years of age to use the Services. You may not attempt to access or use the Services if you are not permitted to do so.<br><br>
        Account Creation. For certain features of the Services you’ll need an account. To register an account, you must first successfully complete the sign-up process. It’s important that you provide us with accurate, complete and current account information and keep this information up to date. If you don’t, we might have to suspend or terminate your account. To protect your account, keep the account details and password confidential, and notify us right away of any unauthorized use. You’re responsible for all activities that occur under your account. You are only allowed to create one account and you agree you won’t share your account with anyone. You also agree that you will not: (i) create another account if we’ve disabled one you previously established, unless you have our prior written consent; (ii) buy, sell, rent or lease access to your account or username, unless you have our prior written consent; (iii) share your account password with anyone; or (iv) log in or try to log in to access the Service through unauthorized third party applications or clients.<br><br>
        Additional Information. Royal may require you to provide additional information and documents at the request of any competent authority or in case of application of any applicable law or regulation, including laws related to anti-laundering (legalization) of incomes obtained by criminal means, or for counteracting financing of terrorism. Royal may also require you to provide additional information and documents in cases where it has reasons to believe that: (i) your account is being used for money laundering or for any other illegal or unauthorized activity; (ii) you have concealed or reported false identification information and other details; or (iii) transactions effected via your account were effected in breach of these Terms. In each such case, Royal, in its sole discretion, may disable (or terminate) your account until such additional information and documents are reviewed by Royal and are accepted as satisfying the requirements of applicable law. If you do not provide complete and accurate information and documents in response to such a request, Royal may refuse to provide the Services to you. We reserve the right to report any activity occurring using the Services to relevant tax authorities as required under applicable law. You are solely responsible for maintaining all relevant Tax (as defined below) records and complying with any reporting requirements you may have as related to our Services. You are further solely responsible for independently maintaining the accuracy of any record submitted to any tax authority including any information derived from the Services.
        Digital Asset Rewards. You may receive NFTs or other digital assets in connection with your use of the Services. In order to receive digital asset rewards, you must first connect a third-party digital asset wallet or sign up for a custodial solution provided by Royal. Such digital assets may be subject to additional terms and conditions, which you will be deemed to accept upon your receipt of such digital assets.<br><br>
        Right to Block Access. We reserve the right, at any time, in our sole discretion, to block access to the Services from certain IP addresses and unique device identifiers.<br><br>
        Equipment and Software. You must provide all equipment, connectivity, and software necessary to connect to the Service. You are solely responsible for any fees, including Internet connection or mobile fees, that you incur when accessing the Service.<br><br>
        About the Services.<br><br>
        Parties. Our Services also allow music professionals (“Artists”) to sell certain non-fungible tokens (each, an “Artist Token”) to third party entities or persons (“Purchasers”) via the Royal Platform. Royal is not a party to any transaction between Artists and any Purchaser. Royal does not control or vet content provided by Artists, nor does Royal assume any responsibility for the accuracy or reliability of any information provided by Artists.
        Our Role. You acknowledge and agree that the Artist Token and any associated royalty payments shall be programmed to enable you to take certain actions to claim royalty payments and/or be self-executing via a governing blockchain’s (e.g., Polygon, Ethereum, and Solana) smart contract system and/or nonfungible token standard and Royal has no control or ability to direct such funds or obligation to collect or distribute such royalties to Artists or Purchasers.<br><br>
        Our Relationship. ROYAL IS A PLATFORM. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICE IS AN ADMINISTRATIVE PLATFORM ONLY. ROYAL FACILITATES TRANSACTIONS BETWEEN A BUYER AND SELLER BUT IS NOT A PARTY TO ANY AGREEMENT BETWEEN THE BUYER AND SELLER OF NFTS OR BETWEEN ANY USERS.
        Terms for Artists.<br><br>
        Royal Fee. We may require payment of a fee for use of certain features of the Services(“RoyalFee”). WereservetherighttomakeanychangesintheRoyalFeeatoursole discretion.<br><br> AnyincreaseintheRoyalFeewillbecommunicatedtoyouatleast14daysbefore it comes into effect.<br><br>
        Additional Obligations. By creating an account and minting an Artist Token, you will be subject to additional terms as set forth in the Artist Terms and Conditions and a Memorandum of Understanding between you and Royal (collectively, the “Artist Terms”).<br><br>
        Terms for Purchasers.<br><br>
        Artist Token Fee. Each Artist may require a fee to purchase one of its Artist Tokens (“Artist Token Fee”). As a Purchaser, you are responsible for paying the Artist Token Fee directly to the Artist pursuant to any additional terms provided by such Artist in the Purchase Terms (defined below).<br><br>
        Credit Card Transactions. You may purchase an Artist Token using a credit card. If you use a credit card to purchase an Artist Token, you may need to claim any streaming royalties associated with your Artist Token by following instructions set forth on the Site from time to time. You expressly authorize us (and any payment processor) to charge your payment card for all purchases you make. For credit card payments, charge your credit card when your purchase an Artist Token. The bank issuing your credit or card may control when to release funds in the case of an order cancellation or refund. We may ask you to supply additional information relevant to your transaction, including your credit card number, the expiration date of your credit card and your email and postal addresses for billing and notification (such information, “Payment Information”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. You may need to provide additional information to verify your identity before completing your transaction (such information is included within the definition of Payment Information). We reserve the right to use the Payment Information you provide us in connection with any payments to provide better service to you should you wish to use our service again in the future and to protect us from fraud and other losses. Completion of a payment transaction is contingent upon:<br><br>
        you providing complete personal, account, transaction and any other information needed,
        authorization of the payment by your credit or debit card company, and
        acceptance of your payment.<br><br>
        Payment and Refund Processing. Payments and refunds facilitated by the Services may be processed by a third-party payment processor (“Payment Processor”). You may be required to create an account with such Payment Processor, and by doing so, you are agreeing to comply with the Payment Processor’s terms of service. You may need to provide additional information to verify your identity, business and tax information and your bank information when you register with the Payment Processor. If, and to the extent, we accept, hold or transmit funds in connection with your purchase, you agree that we do so, on your behalf, as your limited agent and you hereby appoint Royal as your limited agent solely for the purpose of accepting payments from, and refunding payments to, Service Recipients on your behalf. In accepting appointment as your limited agent, we assume no liability for any of your acts or omissions.
        Chargebacks and Payment Holdback. We reserve the right to offset future payments, including any streaming royalties, to you (“Payment Holdbacks”) in certain cases including without limitation: (i) where your Royal Account is subject to excessive chargebacks resulting in additional costs or fees from the Payment Processor; (ii) where the Payment Processor withholds a percentage of a payment as a reserve, for example, as a result of actual or suspected fraudulent activity; or (iii) in certain cases where we decide, in our sole discretion, that it is prudent or necessary to reverse a transaction. We further reserve the right to implement Payment Holdbacks to you if payments are deemed suspicious or fraudulent, at our sole discretion. We will notify you that you are subject to a Payment Holdback and we may require that you provide supporting documentation or information related to the relevant payment(s) and corresponding purchase(s). If you do not provide such supporting evidence within the requested timeframe, we reserve the right to refund the payment and transfer any available funds from your wallet to your identified bank account or otherwise take any action we feel is reasonably necessary to comply with our compliance standards, including those imposed by any relevant financial institution, our Payment Processor, or the credit card network rules, as well as any applicable law.<br><br>
        Restrictions on NFTs Transfers. If you elect to sell, purchase, or trade any NFTs, any financial transactions that you engage in will be conducted solely through the blockchain network governing such NFT and you will be required to make or receive payments exclusively through the cryptocurrency wallet you have connected to your account. We will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. Accordingly, we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the Service. The sole exception to this restriction is if you elect to sell, purchase, or trade any NFTs using credit card payments (as described above).
        Fees. By buying or selling an NFT on the Royal Platform, you agree to pay all applicable fees and you authorize Royal to automatically deduct fees (including any transaction fees, or “Gas Fees”, as applicable) and payment processing fees, including credit card transaction fees, directly from your payment. Gas fees fund the network of computers that run decentralized blockchain networks, meaning that you will need to pay a Gas Fee for each transaction that occurs via a blockchain network.<br><br>
        Your Content; NFTs.<br><br>
        Contact Information. If you have any questions about these Terms or the Services, please contact Royal at notices@royal.io.<br><br>
        Royal Markets Inc.<br><br>
        P.O. Box 757<br><br>
        Austin, TX 78767
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  components: {},
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>